import React, { Fragment } from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import backgroundExamenes from '../assets/images/backgroundExamenes.jpg';
import _627 from '../assets/images/627.jpg';
import TCL from '../assets/images/TCL.jpg';
import examenes2 from '../assets/images/examenes2.jpg';
import Anglia from '../assets/images/Logo-Anglia.png';
import linguaSkill from '../assets/images/Logo-Linguaskill_from_Cambridge.png';
import Banner from '../components/Banner';
import DrawerButton from '../components/DrawerButton';

const Examenes = () => {
  return (
    <Fragment>
      <DrawerButton />
      <Layout>
        <Banner
          title="Exámenes"
          subtitle="¡Desde los iniciales hasta Cambridge!"
          backgroundImage={`url("${backgroundExamenes}")`}
        />
        <div className="contentContainer">
          <div className="introductionContainer">
            <p>Exámenes Internacionales</p>
          </div>
          <div class="introductionImages">
            <img src={_627} alt="" />
            <img src={examenes2} alt="" />
          </div>
          <div className="examsContainer">
            <div className="cambridge">
              <h2>Universidad de Cambridge</h2>
              <div className="separator" />
              <div className="mobileSeparator" />
            </div>
            <div className="examBox">
              <p>KET</p>
            </div>
            <div className="examDescription">
              <h3 className="examTitle">KET</h3>
              <p className="examText">
                El examen A2 Key (KET) Cambridge corresponde a un nivel básico
                de inglés para propósitos académicos y sociales. El Key English
                Test es el primer nivel de Cambridge English y corresponde con
                el nivel de inglés A2 del Marco Común Europeo de Referencia para
                las Lenguas del Consejo de Europa. KET reconoce la habilidad de
                desenvolverse con comunicaciones habladas y escritas en el día a
                día a un nivel básico. ¿Tenés habilidades lingüísticas básicas?
                ¿podés entender textos simples? ¿podés comunicarte en
                situaciones familiares? ¿podés entender avisos simples o
                instrucciones de voz? Si es así, A2 Key (KET) es un examen
                adecuado. El examen A2 Key (KET) Cambridge es realizado por más
                de 40.000 personas cada año en más de sesenta países.
              </p>
            </div>
            <div className="examBox">
              <p>PET</p>
            </div>
            <div className="examDescription">
              <h3 className="examTitle">PET</h3>
              <p className="examText">
                El Preliminary English Test – Examen B1 Preliminary (PET)
                Cambridge – es un nivel de inglés pre intermedio con propósitos
                académicos y profesionales. El Preliminary English Test
                corresponde con el segundo nivel de Cambridge English. El examen
                B1 Preliminary (PET) Cambridge es un nivel pre intermedio en el
                Marco Europeo Común de Referencia para las Lenguas. Si el
                candidato es capaz de desenvolverse con comunicaciones orales y
                escritas cotidianas (por ejemplo, la lectura de textos y
                artículos simples, escribir cartas personales o tomar apuntes
                durante una reunión) este examen es adecuado para él. El examen
                B1 Preliminary (PET) Cambridge es realizado por más de 80.000
                personas cada año en más de ochenta países y es una
                cualificación importante para trabajar o estudiar en el
                extranjero o desarrollar una carrera en negocios
                internacionales. Es también una preparación útil para exámenes
                de nivel más alto, como el FCE (First Certificate in English)
                CAE (Certificate in Advanced English) y CPE (Certificate of
                Proficiency in English)
              </p>
            </div>
            <div className="examBox">
              <p>FCE</p>
            </div>
            <div className="examDescription">
              <h3 className="examTitle">FCE</h3>
              <p className="examText">
                El curso preparatorio para Cambridge First (FCE) brinda a los
                alumnos las herramientas necesarias para rendir el examen
                internacional del mismo nombre, certificación internacional de
                la Universidad de Cambridge reconocida a nivel mundial como
                indicador de nivel de idioma inglés como lengua extranjera. Se
                trata de un curso muy elegido por personas que quieren ampliar
                su perspectiva laboral y educativa. Durante el curso se realiza
                un entrenamiento enfocado en la estructura del examen, con
                clases donde se trabajan las destrezas lingüísticas requeridas:
                comprensión escrita (reading y use of English), expresión
                escrita (writing), comprensión oral (listening) y expresión oral
                (speaking), así como conocimientos de gramática y vocabulario.
              </p>
            </div>
            <div className="examBox">
              <p>CAE</p>
            </div>
            <div className="examDescription">
              <h3 className="examTitle">CAE</h3>
              <p className="examText">
                El curso preparatorio para Cambridge Advanced (CAE) brinda a los
                alumnos las herramientas necesarias para rendir el examen
                internacional del mismo nombre, certificación internacional de
                la Universidad de Cambridge reconocida a nivel mundial como
                indicador de nivel de idioma inglés como lengua extranjera. Se
                trata de un curso muy elegido por personas que cuentan con nivel
                avanzado del idioma, que desean aprovechar al máximo tanto sus
                estudios como su actividad profesional. Durante el curso se
                realiza un entrenamiento enfocado en la estructura del examen,
                con clases donde se trabajan las destrezas lingüísticas
                requeridas: comprensión escrita (reading y use of English),
                expresión escrita (writing), comprensión oral (listening) y
                expresión oral (speaking), así como conocimientos de gramática y
                vocabulario.
              </p>
            </div>
            <div className="examBox">
              <p>CPE</p>
            </div>
            <div className="examDescription">
              <h3 className="examTitle">CPE</h3>
              <p className="examText">
                El curso preparatorio para Cambridge Proficiency (CPE) brinda a
                los alumnos las herramientas necesarias para rendir el examen
                internacional del mismo nombre, certificación internacional de
                la Universidad de Cambridge reconocida a nivel mundial como
                indicador de nivel de idioma inglés como lengua extranjera. Se
                trata de un curso muy elegido por personas que cuentan con nivel
                muy avanzado del idioma, que desean demostrar que cuentan con un
                dominio excepcional de la lengua. Durante el curso se realiza un
                entrenamiento enfocado en la estructura del examen, con clases
                donde se trabajan las destrezas lingüísticas requeridas:
                comprensión escrita comprensión escrita (reading y use of
                English), expresión escrita (writing), comprensión oral
                (listening) y expresión oral (speaking), así como conocimientos
                de gramática y vocabulario.
              </p>
            </div>
            <div className="mobileSeparator" />

            <img className="courseImage" src={linguaSkill} alt="" />

            <div className="examDescription">
              <div className="specialTitle">
                <h3 className="examTitle">LINGUASKILL</h3>
                <p className="disclaimer">(*Modalidad online*)</p>
              </div>
              <p className="examText">
                Es un test online y multinivel de Cambridge con el que podrás
                acreditar tu nivel de inglés (desde A1 hasta C1 o superior) el
                día que quieras y desde tu casa. Elegí qué módulos rendir y
                combinalos como quieras: <br></br>
                <br></br>Reading & Listening / Writing / Speaking<br></br>
                <br></br>Linguaskill se puede aplicar y supervisar fácilmente en
                tu propio lugar en el momento que más te convenga. Lo único que
                necesitas es una computadora, conexión a Internet, un micrófono
                y conjunto de audífonos.
              </p>
            </div>
            <div className="mobileSeparator" />

            <img className="courseImage" src={TCL} alt="" />

            <div className="examDescription">
              <div className="specialTitle">
                <h3 className="examTitle">TRINITY COLLEGE LONDON</h3>
                <p className="disclaimer">(*Modalidad online y presencial*)</p>
              </div>
              <div className="separator" />
              <div className="transparentSeparator" />
              <div className="specialTitle">
                <h4>GESE</h4>
                <h4 className="webColons">:</h4>
              </div>

              <p className="examText">
                Los exámenes GESE (Graded Examinations in Spoken English)
                evalúan la habilidad de los estudiantes para comunicarse
                oralmente en inglés con un hablante nativo. Estos se dividen en
                doce grados, distribuidos en 4 estadios, lo que permite ubicar
                fácilmente a los alumnos en el nivel adecuado.
              </p>
              <div className="specialTitle">
                <h4>ISE</h4>
                <h4 className="webColons">:</h4>
              </div>
              <p className="examText">
                Los exámenes ISE (Integrated skills in English) evalúan las
                cuatro habilidades lingüísticas: escritura, lectura, comprensión
                y expresión oral. Evalúan la capacidad de los candidatos para
                interactuar en inglés de una manera auténtica y significativa a
                través del uso integrado de las tareas de lectura y escritura y
                las de escucha y habla.
              </p>
            </div>
            <div className="mobileSeparator" />

            <img className="courseImage" src={Anglia} alt="" />

            <div className="examDescription">
              <div className="specialTitle">
                <h3 className="examTitle">ANGLIA EXAMINATIONS</h3>

                <p className="disclaimer">(*Modalidad online y presencial*)</p>
              </div>
              <div className="separator" />
              <div className="transparentSeparator" />
              <p className="examText">
                Anglia ESOL Examinations es una entidad examinadora
                internacional que actualmente opera en más de 50 países en todo
                el mundo. La Casa Matriz de nuestra organización está ubicada en
                el Departamento de Operaciones Internacionales de Chichester
                College (entidad británica educativa pública, subvencionada por
                el gobierno de Gran Bretaña), situado en el condado de West
                Sussex, Inglaterra.<br></br>
                <br></br>Anglia ESOL Examinations ofrece:<br></br>
                <br></br>· Pruebas Orales de Logro para niños a partir de los 4
                años de edad (3 niveles) <br></br>· Exámenes de Inglés General
                (14 niveles) <br></br>· Exámenes de Inglés de Negocios (4
                niveles) <br></br>· Exámenes para Docentes (3 niveles) <br></br>
                · Certificaciones en Traducción Aplicada (2 niveles)<br></br>
                <br></br>
                Nuestros exámenes cubren todas las edades (niños, adolescentes y
                adultos) y se ajustan a todos los niveles del idioma, desde
                principiante hasta experto. Cabe destacar que nuestros exámenes
                se encuentran alineados al Marco Común Europeo de Referencia
                para las Lenguas (MCER).<br></br>Anglia Examinations trabaja en
                conjunto con AIM Awards, y de esta manera, logra ofrecer
                certificaciones acreditadas por Ofqual, Inglaterra. AIM Awards
                es una organización acreditadora ubicada en el Reino Unido,
                quien ofrece un gran número de certificaciones en distintos
                niveles y en diferentes áreas de estudio.
              </p>
            </div>
          </div>
        </div>
        <div className="contactUs">
          <h1>CONSULTANOS</h1>
        </div>
        <Footer />
      </Layout>
    </Fragment>
  );
};

export default Examenes;
